import React from 'react';
import logo from '../img/logo-white.svg';
import './Navbar.sass';

const Navbar = class extends React.Component {
  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-brand-logo">
              <a
                href="//www.bettergov.org/"
                title="Logo"
                style={{ lineHeight: 1 }}
              >
                <img src={logo} alt="BGA" />
              </a>
            </div>
          </div>

          <div className="navbar-menu">
            <div className="navbar-start">
              <div className="navbar-tagline" style={{ display: 'none' }}>
                <strong style={{ color: 'currentColor' }}>
                  Better Government Association
                </strong>
                <br />
                <span style={{ fontWeight: 400 }}>
                  Illinois’ Nonpartisan Full-Service Watchdog
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;

import React from 'react';
import './Footer.sass';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer footer-simple">
        <div className="footer-copyright is-size-7">
          © {new Date().getFullYear()}{' '}
          <a href="//www.bettergov.org/">Better Government Association</a>
        </div>
      </footer>
    );
  }
};

export default Footer;

import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/FooterSimple';
import Navbar from '../components/Navbar';
import './all.sass';
import useSiteMetadata from './SiteMetadata';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, fab);

const TemplateWrapper = ({ children }) => {
  const { title, siteUrl, description, image } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" className="async-hide" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@bettergov" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <link rel="preconnect" href="//google-analytics.com" />
        <link rel="preconnect" href="//googletagmanager.com" />
      </Helmet>
      <Navbar />
      <main id="main-content">{children}</main>
      <Footer />
    </>
  );
};

export default TemplateWrapper;
